/*global S3Upload*/
import Api from '@/resources/NajaSocialApi'

const _uploadImage = function (image, callback) {
  if (image === undefined) {
    return
  }

  const url = `${Api.oldApiBaseURL}/s3_signed_upload`
  const s3Upload = new S3Upload(url, image, {
    resourceName: 'medical_report',
    resourceProperty: 'image',
  })

  s3Upload.bind('error', (status, file) => {
    // Toast.show(
    //   'Não foi possível fazer o upload de sua imagem. Por favor, tente novamente!',
    //   'alert'
    // )
  })

  s3Upload.bind('success', publicUrl => {
    callback(publicUrl)
  })

  s3Upload.start()
}

const beforeUpload = function (images) {
  const editor = this
  for (var i = 0; i < images.length; i++) {
    _uploadImage(images[i], function (publicUrl) {
      editor.image.insert(publicUrl, null, null, editor.image.get(), null)
    })
  }
  return false
}

export default {
  beforeUpload: beforeUpload,
}
